<template>
    <div style="width: 1080px; height:1920px;" class="relative overflow-hidden">

        <div class="absolute inset-0 overflow-hidden">
            <div style="margin-top: 180px;">
                <img :src="img" class="w-full h-full object-cover filter blur-lg transform rotate-6 scale-150">
            </div>
        </div>

        <div class="absolute inset-0">
            <div class="bg-gradient-to-b gradient from-black to-transparent absolute inset-0"
                 style="bottom: 25%;"></div>
            <div class="gradient from-black to-transparent absolute inset-0 bg-gradient-to-t"
                 style="top: 25%;"></div>
        </div>

        <div class="absolute inset-0">
            <div class="absolute shadow-2xl" style="top: 692px; bottom: 612px; left: 200px; right: 200px;">
                <img :src="img" class="w-full h-full object-cover">
            </div>
        </div>


        <img src="./../../../assets/img/social/LOGO_AWARDS.png" class="z-50 absolute"
             style="width: 500px; left: 50%; top: 137px; margin-left: -250px;">
        <div class="absolute inset-x-0 flex justify-center text-white w-full z-50"
             style="text-align: center; font-size: 46px; top: 335px;">
            <div>{{ year }}</div></div>

        <div class="absolute inset-x-0 z-50" style="top:424px;">
            <div class="text-4xl uppercase text-white font-regular right-4 text-left text-center" style="font-size: 50px;">
                <div v-if="layout === 'live'">Miglior artista live</div>
                <div v-else>Miglior album italiano</div>
            </div>
        </div>

        <div class="absolute inset-0 opacity-10 mix-blend-color" :style="{'background-color': bgColor}"></div>

        <div class="absolute inset-0 overflow-hidden">
            <div
                :class="{'best_italian_artist_shows' : layout === 'live', 'best_italian_records': layout !=='live'}"
                class="absolute  bg-red-500 inset-0 transform bg-gradient-to-br from-poll-gradient-from to-poll-gradient-to via-poll-gradient-via"
                style="clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%, -0.26% 72.92%, 102.58% 72.46%, 101.86% 79.23%, 100.34% 30.85%, -1px 30.92%);" ></div>

        </div>

        <div
            class="absolute inset-0 text-white font-light uppercase flex flex-col justify-center items-center leading-none"
            style="margin-top: 1300px; font-size: 120px;">
            <div ref="el" style="width: 1000px; height: 100px;" class="flex items-center justify-center">
                <div v-html="name" class="font-semibold text-center leading-none"></div>
                <div v-html="albumName" class="opacity-90 font-normal text-center leading-none"></div>
            </div>
            <div class="opacity-80" style="font-size: 60px;">Vota ora</div>
            <div class="text-3xl mt-2 tracking-wide font-semibold" style="letter-spacing: 0.3em;">www.rockolawards.it</div>
        </div>

    </div>
</template>


<script>
import textFit from 'textfit'

export default {
    props: ['year', 'img', 'name', 'album-name', 'layout'],
    data() {
        return {
            live_bg_color: '#4d8cf6',
            album_bg_color: '#c94646',
        }
    },
    computed: {
        bgColor() {
            return this.layout === 'live'
                ? this.live_bg_color
                : this.album_bg_color;
        },
        templateImage() {
            return this.layout === 'live'
                ? 'Template_RockolAWARDS_miglior_live.png'
                : 'Template_RockolAWARDS_miglior_album.png';
        }
    },
    mounted() {
        setTimeout(() => {
            textFit(this.$refs['el']);
        }, 500)
    }
}
</script>
