<template>
    <div style="width: 940px; height:768px;" class="relative">

        <div class="absolute inset-0 overflow-hidden">
            <div style="margin-top: 180px;">
                <img :src="img" class="w-full h-full object-cover filter blur-lg transform rotate-6 scale-150">
            </div>
        </div>

        <div class="absolute top-0 left-0 z-50 text-white"
             style="top: 100px; left: 140px;text-align: center; font-size: 26px;">{{ year }}
        </div>

        <div class="absolute inset-0">
            <div class="bg-gradient-to-b gradient from-black to-transparent absolute inset-0"
                 style="bottom: 30%;"></div>
            <div class="gradient from-black to-transparent absolute inset-0 bg-gradient-to-t"
                 style="top: 30%;"></div>
        </div>

        <div class="absolute inset-0">
            <div class="inset-x-0 absolute " style="inset: 166px 180px;">
                <div class="absolute inset-0 ">
                    <img :src="img" class="w-full h-full object-contain">
                </div>
            </div>
        </div>

        <div class="absolute inset-0 opacity-10 mix-blend-color" :style="{'background-color': bgColor}"></div>

        <div class="absolute inset-0 overflow-hidden">
            <div
                :class="{'best_italian_artist_shows' : layout === 'live', 'best_italian_records': layout !=='live'}"
                class="absolute  bg-red-500 inset-0 transform bg-gradient-to-br from-poll-gradient-from to-poll-gradient-to via-poll-gradient-via"
               style="clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%, -0.26% 80.99%, 102.12% 80.53%, 101.86% 79.23%, 100.8% 19.13%, -2px 19.46%);" ></div>

            <img src="./../../../assets/img/social/LOGO_AWARDS.png" class="absolute"
                 style="width: 200px;  left: 73px;  top: 17px;">
        </div>

        <div class="absolute inset-x-0 px-4" style="top:62px;">
            <div class="_bg-red-500 text-4xl uppercase text-white font-regular right-4 text-left text-center"
                 style="font-size: 45px; margin-left: 271px;">
                <div v-if="layout === 'live'">Miglior artista live</div>
                <div v-else>Miglior album italiano</div>
            </div>
        </div>

        <div
            class="absolute inset-0 text-white font-light uppercase flex flex-col justify-center items-center leading-none"
            style="margin-top: 630px;">
            <div ref="el" style="width: 700px; height: 70px;" class=" _bg-red-500 flex items-center justify-center">
                <div v-html="name" class="font-semibold text-center leading-none"></div>
                <div v-html="albumName" class="opacity-90 font-normal text-center leading-none"></div>
            </div>

            <div class="text-3xl">Vota ora su <span class="font-semibold">www.rockolawards.it</span>
            </div>

        </div>

    </div>
</template>


<script>
import textFit from 'textfit'

export default {
    props: ['year', 'img', 'name', 'album-name', 'layout'],
    data() {
        return {
            live_bg_color: '#d00369',
            album_bg_color: '#116786',
        }
    },
    computed: {
        bgColor() {
            return this.layout === 'live'
                ? this.live_bg_color
                : this.album_bg_color;
        },
    },
    mounted() {
        setTimeout(() => {
            textFit(this.$refs['el']);
        }, 500)
    }
}
</script>
